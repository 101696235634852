import React from 'react';
import "./Title.css"
import Button from '@mui/material/Button';


function Title({setTitlePage}) {
    return (
        <div className="background">
            <div className="titleContainer">
                <h1 className="pageTitle"> Welcome to TicToss! </h1>
                <p className="paragraph titlePara">
                    Want to invest in a crypto-currency 
                    but unsure of which one to go into? TicToss is here to help you enter the crypto
                    bear market by randomizing thousands of cryptocurrencies across multiple exchanges
                    and networks to present you with the next possible crypto moon shot.
                </p>
            </div>
            <div className="container">
                <p className="text"> Fetch a Random Cryptocurrency! </p>
                <Button 
                    onClick={() => setTitlePage(false)} 
                    className="randomButton" 
                    variant="contained">
                        Randomize!
                </Button>
            </div>
        </div>
    )
}

export default Title
