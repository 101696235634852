import React, { useState } from 'react'
import "./Home.css"
import Title from "./Title.js";
import Crypto from "./Crypto.js";

function Home() {
    const [titlePage, setTitlePage] = useState(true);
    return (
        <div className="background__home">
            {titlePage === true && <Title setTitlePage={setTitlePage}/>}
            {titlePage === false && <Crypto setTitlePage={setTitlePage}/>}
        </div>
    )
}

export default Home
