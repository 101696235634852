import './App.css';
import Home from './Home.js';

function App() {
  return (
      <div className="App">
            <Home />
      </div>
  );
}

export default App;
