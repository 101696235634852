import React from 'react'
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CurrencyFormat from "react-currency-format";
import "./Crypto.css";

// "start": "concurrently \"react-scripts start\" \"nodemon server\" "

const Crypto = () => {
  const [data, setData] = useState({});
  const [refresh, setRefresh] = useState(0);

useEffect(() => {
  (async () => {
      var sendData = await fetch("/random");
      sendData = await sendData.json();
      setData(sendData)
      console.log(sendData)
  })()
},[refresh])

return (
    <div className="cryptoContainer">
      <div className ="cryptoAll">
        <div className='cryptoData'>
          {data.logo && <img className="logo" alt="Crypto Logo" src={data.logo} />}
          <div className='cryptoContent'>
            {data.name && (<h1 style={{fontSize: "24px", fontWeight: "500"}} >{data.name} ({data.symbol})</h1>)}
            {data.price && (
              <CurrencyFormat
                renderText={(value) => <h2>Price: {value}</h2>}
                decimalScale={ 
                  data.price > 100 ? 2 :
                  data.price > 1 ? 3 : 
                  data.price > 0.1 ? 4 : 
                  data.price > 0.001 ? 8 :
                  data.price > 0.000001 ? 10 :
                  12
                }
                displayType={"text"}
                value={data.price}
                thousandSeparator={true}
                prefix={"$"}
              />
            )}
            {(data.marketCap || data.marketCap !== 0) && (
              <CurrencyFormat
                renderText={(value) => <h2> Market Cap: {value}</h2>}
                decimalScale={2}
                displayType={"text"}
                value={data.marketCap}
                thousandSeparator={true}
                prefix={"$"}
              />
            )}
            {(data.change || data.change !== 0) && (
              <CurrencyFormat
                className='percentChange'
                renderText={(value) => 
                  <div style={{display: "flex"}}> 
                    <h2> Percent Change (24hr): <span className='percentChange' style={value < 0 ? {color: "#ff6347"} : {color: "#90EE90"}} > {value}% </span> </h2> 
                  </div>}
                decimalScale={2}
                displayType={"text"}
                value={data.change}
                thousandSeparator={true}
                // prefix={"$"}
              />
            )}
            {(data.circulatingSupply || data.circulatingSupply !== 0) && (
              <CurrencyFormat
                renderText={(value) => (
                  <h2>
                    {" "}
                    Circulating Supply: {value} {data.symbol}
                  </h2>
                )}
                decimalScale={0}
                displayType={"text"}
                value={data.circulatingSupply}
                thousandSeparator={true}
                // prefix={"$"}
              />
            )}
            {(data.maxSupply && data.maxSupply !== 0) && (
              <CurrencyFormat
                renderText={(value) => (
                  <h2>
                    {" "}
                    Max Supply: {value} {data.symbol}
                  </h2>
                )}
                decimalScale={0}
                displayType={"text"}
                value={data.maxSupply}
                thousandSeparator={true}
                // prefix={"$"}
              />
            )}
          </div>
        </div>
        <div className='socials'>
          {data.reddit && (
            <a target={"_blank"} title={data.reddit.length > 0 ? "" :"Unavailable"} href={data.reddit[0]} rel="noreferrer">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill={data.reddit.length > 0 ?'#FF5700' : "rgba(167, 166, 186, 0.6)"}
                viewBox="0 0 24 24"
                className='redditSVG'
              >
                <path d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z" />
              </svg>{" "}
            </a>
          )}
          {data.twitter && (
            <a target={"_blank"} title={data.twitter.length > 0 ? '' : "Unavailable"} href={data.twitter[0]} rel="noreferrer">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill={data.twitter.length > 0 ? '#1DA1F2' :'rgba(167, 166, 186, 0.6)'}
                viewBox="0 0 24 24"
                className='twitterSVG'
              >
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
              </svg>
            </a>
          )}
          {data.website && (
            <a target={"_blank"} title={data.website.length > 0 ? "" : "Unavailable"} href={data.website[0]  } rel="noreferrer">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill={data.website.length > 0 ? "rgb(63, 0, 136)" : "rgba(167, 166, 186, 0.6)"}
                viewBox="0 0 24 24"
                className='websiteSVG'
              >
                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 16.057v-3.057h2.994c-.059 1.143-.212 2.24-.456 3.279-.823-.12-1.674-.188-2.538-.222zm1.957 2.162c-.499 1.33-1.159 2.497-1.957 3.456v-3.62c.666.028 1.319.081 1.957.164zm-1.957-7.219v-3.015c.868-.034 1.721-.103 2.548-.224.238 1.027.389 2.111.446 3.239h-2.994zm0-5.014v-3.661c.806.969 1.471 2.15 1.971 3.496-.642.084-1.3.137-1.971.165zm2.703-3.267c1.237.496 2.354 1.228 3.29 2.146-.642.234-1.311.442-2.019.607-.344-.992-.775-1.91-1.271-2.753zm-7.241 13.56c-.244-1.039-.398-2.136-.456-3.279h2.994v3.057c-.865.034-1.714.102-2.538.222zm2.538 1.776v3.62c-.798-.959-1.458-2.126-1.957-3.456.638-.083 1.291-.136 1.957-.164zm-2.994-7.055c.057-1.128.207-2.212.446-3.239.827.121 1.68.19 2.548.224v3.015h-2.994zm1.024-5.179c.5-1.346 1.165-2.527 1.97-3.496v3.661c-.671-.028-1.329-.081-1.97-.165zm-2.005-.35c-.708-.165-1.377-.373-2.018-.607.937-.918 2.053-1.65 3.29-2.146-.496.844-.927 1.762-1.272 2.753zm-.549 1.918c-.264 1.151-.434 2.36-.492 3.611h-3.933c.165-1.658.739-3.197 1.617-4.518.88.361 1.816.67 2.808.907zm.009 9.262c-.988.236-1.92.542-2.797.9-.89-1.328-1.471-2.879-1.637-4.551h3.934c.058 1.265.231 2.488.5 3.651zm.553 1.917c.342.976.768 1.881 1.257 2.712-1.223-.49-2.326-1.211-3.256-2.115.636-.229 1.299-.435 1.999-.597zm9.924 0c.7.163 1.362.367 1.999.597-.931.903-2.034 1.625-3.257 2.116.489-.832.915-1.737 1.258-2.713zm.553-1.917c.27-1.163.442-2.386.501-3.651h3.934c-.167 1.672-.748 3.223-1.638 4.551-.877-.358-1.81-.664-2.797-.9zm.501-5.651c-.058-1.251-.229-2.46-.492-3.611.992-.237 1.929-.546 2.809-.907.877 1.321 1.451 2.86 1.616 4.518h-3.933z" />
              </svg>
            </a>
          )}
        </div>
      </div>
        <Button className="cryptoButton" onClick={() => setRefresh(refresh + 1)}>
          RE-ROLL!
        </Button>
      {/* <Button className="cryptoButton" onClick={() => setTitlePage(true)}>
        Back
      </Button> */}

    </div>
);

};

export default Crypto; 